<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Candidate</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="First Name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="addCandidate.first_name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Middle Name"
              label-cols-md="4"
              class="text-right"
            >
              <b-form-input
                v-model="addCandidate.middle_name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Last Name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="addCandidate.last_name"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Email"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  v-model="addCandidate.email_address"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Phone"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required|integer|digits:10"
              >
                <b-form-input
                  v-model="addCandidate.phone_number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Skype Id"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Skype Id"
                rules="required"
              >
                <b-form-input
                  v-model="addCandidate.skype_id"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Date of Birth"
              label-cols-md="4"
              class="text-right"
            >
              <b-input-group>
                <flat-pickr
                  v-model="date_of_birth"
                  class="form-control"
                  :config="$store.state.notGraterThanToday"
                  placeholder="MM-DD-YYYY"
                  :clear-button="true"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeDateOfBirth"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Gender"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <v-select
                  v-model="addCandidate.gender_id"
                  :options="genders"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Visa"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Visa"
                rules="required"
              >
                <v-select
                  v-model="addCandidate.visa.type_id"
                  :options="visaTypes"
                  :reduce="item => item.id"
                  :clearable="false"
                  placeholder="Select"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            offset="2"
            class="mt-1"
          >
            <b-form-checkbox
              v-model="addCandidate.visa.validated"
            >
              Visa Validated
            </b-form-checkbox>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Expiry Date"
              label-cols-md="4"
              class="text-right"
            >
              <b-input-group>
                <flat-pickr
                  v-model="visa_expairy_date"
                  class="form-control"
                  :config="$store.state.config"
                  placeholder="MM-DD-YYYY"
                  :clear-button="true"
                />
                <b-input-group-append>
                  <b-button
                    class="px-1 outline-grey"
                    variant="outline-secondary"
                    @click="removeVisaExpairyDate"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Interviews
          </h4>
        </div>
        <b-row
          v-for="(item, index) in addCandidate.interviews"
          :key="index"
          class="mt-1"
        >
          <b-col cols="6">
            <b-form-group
              label="Date"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="addCandidate.interviews[index].date_text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Time"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="addCandidate.interviews[index].time"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Interviewer Name"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="addCandidate.interviews[index].name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Status"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="addCandidate.interviews[index].status"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="addCandidate.interviews.length == 0"
          class="text-center mt-1"
        >
          <p> No Interviews Scheduled </p>
        </div>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Experience
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="option in addCandidate.experiences"
            :key="option.id"
            :value="option.id"
            cols="4"
          >
            <b-form-group
              :label="option.name"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                :name="option.name"
                rules="required"
              >
                <b-form-spinbutton
                  v-model="option.years"
                  :min="0"
                  :max="10"
                  :step="0.5"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div
          v-if="addCandidate.experiences.length === 0"
          class="text-center mt-1"
        >
          <p> Experience on Technology Not Available </p>
        </div>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Documents
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                v-model="documentNameId"
                :clearable="true"
                placeholder="Select"
                :options="documentNames"
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Attachment"
              label-cols-md="4"
              class="text-right"
            >
              <div class="d-flex">
                <input
                  ref="myFileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="form-control text-left"
                  @change="attahamentFileSelected"
                >
                <b-button
                  variant="outline-primary"
                  class="ml-1 px-1"
                  @click="addFile"
                >
                  Upload
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button-group
              v-for="(option, index) in addCandidate.documents"
              :key="option.id"
              :value="option.id"
              class="mr-1"
            >
              <b-badge
                :href="option.document_path"
                :title="option.file_name"
                target="option.document_path"
                variant="light-primary"
                class="rounded-right-0 line-height-inherit"
              >
                <span>{{ option.file_name }}</span>
              </b-badge>
              <b-button
                variant="danger"
                class="px-50 py-25"
                @click="removeFile(option, index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Comments
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col
            v-for="(comment, index) in addCandidate.comments"
            :key="index"
            cols="6"
          >
            <div class="d-flex">
              <div class="left w-100">
                <b-form-group
                  label="Comment"
                  label-cols-md="4"
                  class="text-right"
                >
                  <b-form-textarea
                    v-model="comment.comment"
                    rows="2"
                  />
                </b-form-group>
              </div>
              <div class="right ml-1">
                <b-button
                  v-show="index === 0"
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  @click="addComment"
                >
                  <feather-icon icon="PlusIcon" />
                </b-button>
                <b-button
                  v-show="index !== 0"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeComment(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Candidate
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import router from '@/router/index'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormTextarea, BButtonGroup, BButton, BFormCheckbox, BBadge, BFormSpinbutton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import User from '@/js/user'
import Candidate from '@/js/candidates'
import Constants from '@/js/constant'
import moment from 'moment'
import LIST from '@/js/lists'
import Auth from '@/auth/authService'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormTextarea,
    vSelect,
    BButton,
    BButtonGroup,
    BFormCheckbox,
    flatPickr,
    BBadge,
    BFormSpinbutton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      addCandidate: {
        job_id: null,
        employer_id: null,
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        skype_id: '',
        gender_id: null,
        date_of_birth: null,
        submitted_date: null,
        visa: {
          type_id: null,
          expiration_date: null,
          validated: false,
        },
        interviews: [],
        comments: [],
        documents: [],
      },
      url: null,
      documentNameId: null,
      date_of_birth: null,
      visa_expairy_date: null,
      selectedFile: null,
      genders: [],
      visaTypes: [],
      documentNames: [],
      configs: {
        start: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-1900', 'MM-DD-YYYY'),
          maxDate: moment(),
        },
        end: {
          format: 'MM-DD-YYYY',
          useCurrent: false,
          showClear: false,
          showClose: false,
          minDate: moment('01-01-2000', 'MM-DD-YYYY'),
        },
      },
    }
  },
  mounted() {
  },
  created() {
    if (User.profile().job_exist) {
      this.addCandidate.job_id = this.$route.params.job_id
      this.addCandidate.employer_id = User.profile().job_parent.parent_id
      Candidate.getCandidateById(this, this.$route.params.id)
      LIST.getGenderFullList(this)
      LIST.getProspectDocumentNameFullList(this)
      LIST.getVisaFullList(this)
    } else if (User.profile().invoice_exist) {
      window.location.hash = '#/invoices'
    } else if (User.profile().timesheet_exist) {
      window.location.hash = '#/timesheets'
    } else {
      Auth.logout()
    }
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.date_of_birth !== null) {
            this.addCandidate.date_of_birth = Constants.getEprmMonthDateFromString(this.date_of_birth)
          }
          if (this.visa_expairy_date !== null) {
            this.addCandidate.visa.expiration_date = Constants.getEprmMonthDateFromString(this.visa_expairy_date)
          }
          this.addCandidate.submitted_date = Constants.getEprmMonthDateFromString(moment())
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addCandidate.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          this.addCandidate.job_id = this.$route.params.job_id
          this.showLoading = true
          Candidate.updateCandidate(this, this.$route.params.id, this.addCandidate)
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(file, position) {
      const dis = this
      dis.addCandidate.documents.splice(position, 1)
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addCandidate.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    deleteConfirm(obj, position) {
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.addCandidate.documents.splice(position, 1)
        }
      })
    },
    setTypes(types) {
      this.types = types
    },
    setCompanies(companies) {
      this.companies = companies
    },
    setVisaTypes(visaTypes) {
      this.visaTypes = visaTypes
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setTechnologies(technologies) {
      this.technologies = technologies
      for (let i = 0; i < this.technologies.length; i += 1) {
        this.technologies[i].years = 0.00
      }
    },
    setGenders(genders) {
      this.genders = genders
    },
    removeDateOfBirth() {
      this.addCandidate.date_of_birth = null
      this.date_of_birth = null
    },
    removeVisaExpairyDate() {
      this.addCandidate.visa.expiration_date = null
      this.visa_expairy_date = null
    },
    setCandidate(candidate) {
      this.addCandidate = candidate
      if (this.addCandidate.comments.length === 0) {
        this.addComment()
      }
      for (let i = 0; i < this.addCandidate.documents.length; i += 1) {
        this.addCandidate.documents[i].file_name = this.addCandidate.documents[i].document
      }
      this.date_of_birth = candidate.date_of_birth
      this.visa_expairy_date = candidate.visa.expiration_date
    },
    candidateUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    candidateNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addComment() {
      const comment = {
        comment: '',
        date: Constants.getEprmMonthDateFromString(moment()),
        time: moment().format('hh:mm a'),
      }
      this.addCandidate.comments.push(comment)
    },
    removeComment(index) {
      this.addCandidate.comments.splice(index, 1)
    },
    back() {
      router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  transition: .35s height;
}
</style>
